/* Modal styles */
.creator-tooltip-modal .modal-content {
    background-color: #1E1E1E;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    border: 2px solid #9c27b0; /* Example of border color */
  }
  
  /* Avatar and Name Section */
  .creator-avatar-section {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .creator-avatar-wrapper {
    position: relative;
    width: 60px;
    height: 60px;
  }
  
  .creator-avatar-large {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .active-status-circle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 12px;
    height: 12px;
    background-color: #9c27b0;
    /* border: 2px solid #1E1E1E;  */
    border-radius: 50%;
  }
  
  .creator-info {
    display: flex;
    flex-direction: column;
  }
  
  .creator-name {
    margin: 0;
    color: white;
    font-size: 1.2rem;
  }
  
  .creator-email {
    color: #9c27b0;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  /* Bio Section */
  .creator-bio {
    font-size: 0.9rem;
    color: #bbb;
    margin-top: 10px;
  }
  
  /* Horizontal Divider */
  .horizontal-divider {
    height: 1px;
    width: 100%;
    background-color: #444;
    margin: 15px 0;
  }
  
  /* Interaction Section */
  .interaction-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .message-input {
    flex-grow: 1;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #333;
    border: none;
    color: #fff;
  }
  
  .icon-group i {
    font-size: 1.3rem;
    color: #fff;
    margin-left: 15px;
    cursor: pointer;
  }
  
  .icon-group i:hover {
    color: #9c27b0;
  }
  
  /* Footer - Role */
  .creator-role {
    margin-top: 15px;
    text-align: center;
    color: #9c27b0;
    font-weight: bold;
  }
  