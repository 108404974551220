.engineering-screen-container {
    background-color: #0A0A0A;
    color: #FFF;
    min-height: 100vh;
    padding: 20px;
  }
  
  .engineering-screen-top-section {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #0A0A0A;
    color: #FFF;
    gap: 20px;
  }
  
  .back-button {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 8px 16px;
    color: #fff;
    font-size: 16px;
  }
  
  .breadcrumbs {
    flex-grow: 1;
    margin-left: 20px;
    font-size: 18px;
  }
  
  .breadcrumbs .active {
    text-decoration: underline;
  }
  
  .metadata {
    display: flex;
    gap: 20px;
    font-size: 14px;
  }
  
  .middle-section {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .color-scale {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .color-bar {
    position: relative;
    width: 50px;
    height: 550px;
    background: linear-gradient(to bottom, red, orange, yellow, green, cyan, blue);
  }
  
  .color-label {
    position: absolute;
    left: 25px; /* Adjusts the distance of the labels from the color bar */
    color: #FFF;
    font-size: 12px;
    white-space: nowrap;
  }
  
  .color-bar .color-label:nth-child(1) { top: 0; left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(2) { top: 10%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(3) { top: 20%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(4) { top: 30%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(5) { top: 40%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(6) { top: 50%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(7) { top: 60%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(8) { top: 70%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(9) { top: 80%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(10) { top: 90%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(11) { top: 100%;left: 110%; transform: translateY(-50%); }
  .color-bar .color-label:nth-child(12) { bottom: 0;left: 110%; transform: translateY(50%); }
  
  .image-section {
    display: flex;
    justify-content: center;
  }
  
  .model-image {
    max-width: 100%;
    height: auto;
  }
  
  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .range-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .range-slider {
    flex-grow: 1;
    appearance: none;
    width: 100%;
    height: 4px;
    background: #CCC;
    border-radius: 2px;
    outline: none;
    margin: 0 10px;
  }
  
  .range-slider::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background: #FFF;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #000;
  }
  
  .range-slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #FFF;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #000;
  }
  
  .time-label {
    color: #FFF;
    font-size: 12px;
  }
  
  .play-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .play-button {
    font-size: 24px;
    color: #FFF;
  }
  
  /* .rewind-button::before {
    content: "↶";
  }
  
  .play-pause-button::before {
    content: "▶️";
  }
  
  .forward-button::before {
    content: "↷";
  } */

  .engineering-custom-dropdown .dropdown-toggle {
    background-color: #d3d3d3; /* Light gray background */
    color: #000; /* Black text color */
    border-radius: 20px; /* Rounded corners */
    padding: 10px 20px; /* Padding for better spacing */
    width: 250px; /* Set a fixed width */
    text-align: left; /* Align text to the left */
  }
  
  .engineering-custom-dropdown .dropdown-menu {
    background-color: #d3d3d3; /* Light gray background */
    border-radius: 10px; /* Rounded corners */
    border: none; /* Remove border */
    width: 200px; /* Set a fixed width */
    margin-top: 5px; /* Space below toggle button */
  }
  
  .engineering-custom-dropdown .dropdown-item {
    color: #000; /* Black text color */
    padding: 10px 20px; /* Padding for better spacing */
  }
  
  .engineering-custom-dropdown .dropdown-item:hover {
    background-color: #b0b0b0; /* Darker gray on hover */
  }
  
  .engineering-custom-dropdown .dropdown-toggle::after {
    float: right; /* Arrow on the right */
    margin-top: 5px; /* Vertical alignment */
  }
  

  
  
  .engineering-custom-dropdown .dropdown-toggle {
    background-color: #d3d3d3;
    color: #000;
    border-radius: 10px;
    padding: 8px 16px;
    width: 200px;
    text-align: left;
  }
  
  .engineering-custom-dropdown .dropdown-menu {
    background-color: #d3d3d3;
    border-radius: 10px;
    border: none;
  }
  
  .breadcrumbs {
    flex-grow: 1;
    color: #fff;
    font-size: 16px;
  }
  
  .breadcrumbs .active {
    text-decoration: underline;
  }
  
  .metadata {
    display: flex;
    gap: 20px;
    font-size: 14px;
    color: #FFF;
  }
  
  .metadata a {
    color: #FFF;
    text-decoration: underline;
  }
  
  .creator-info {
    display: flex;
    align-items: center;
  }
  
  .creator  {
    margin-right: 8px;
    border: 2px solid #FFC107;
    padding: 2px;
    border-radius: 40px;
  }