/* DesignStageDropdown.css */

.designstage-dropdown {
    background-color: #000;
    border: 1px solid #666;
    border-radius: 20px;
    width: 200px;
    color: #aaa;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .designstage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }
  
  .designstage-header .title {
    font-size: 14px;
  }
  
  .designstage-header .icon {
    font-size: 12px;
  }
  
  .designstage-body {
    padding: 10px 15px;
    border-top: 1px solid #333;
  }
  
  .designstage-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #333;
  }
  
  .designstage-item:last-child {
    border-bottom: none;
  }
  
  .info-icon {
    font-size: 14px;
    color: #aaa;
    background-color: #222;
    border-radius: 50%;
    width: 24px; /* Adjust the width to match the height */
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid;
  }
  
  .designstage-dropdown.open {
    border-radius: 20px 20px 0 0;
  }
  
  .designstage-dropdown.open .designstage-body {
    border-radius: 0 0 20px 20px;
  }
  