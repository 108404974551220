.division-container {
    padding: 20px;
    background-color: #0A0A0A;
    color: #fff;
    min-height: 100vh;
  }
  
  .top-section h4 {
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .division-divider {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, #FFFFFF 59.5%, rgba(153, 153, 153, 0) 100%);
    margin-bottom: 20px;
  }
  
  .division-table {
    background-color: #1a1a1a;
    width: 100%;
    color: #fff;
    border: 2px solid #333232;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .division-table tbody tr:hover {
    background: linear-gradient(90deg, #0D0D0D 0%, #717171 100%) !important;
    cursor: pointer;
  }
  
  .division-table tbody tr:hover td {
    background: transparent !important;
  }
  
  .division-table th,
  .division-table td {
    padding: 15px;
    background-color: #1a1a1a;
    color: #d2d2d2 !important;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #444;
    font-family: "FONTSPRING DEMO - neue Singular D Light";
    font-size: 14px;
    font-weight: 320;
    line-height: 17.66px;
    letter-spacing: 0.1em;
  }
  
  .division-table th {
    background-color: #333;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }
  
  .division-table tr:last-child td {
    border-bottom: none;
  }
  
  .division-buttons button {
    border-radius: 20px;
    margin-left: 10px;
  }
  
  .division-buttons .btn-outline-light {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .division-buttons .btn-outline-light img {
    max-width: 100%;
    height: auto;
  }
  
  .modal-header,
  .modal-body,
  .modal-footer {
    background-color: #1a1a1a;
    color: #d2d2d2;
  }
  
  .modal-header {
    border-bottom: 1px solid #444;
  }
  
  .modal-footer {
    border-top: 1px solid #444;
  }
  
  .modal-title {
    font-size: 20px;
    font-weight: 600;
  }
  
  .form-control {
    background-color: #333 !important;
    border: 1px solid #444 !important;
    color: #fff !important;
    font-size: 14px;
  }
  
  .form-control:focus {
    background-color: #333 !important;
    border: 1px solid #888 !important;
    box-shadow: none !important;
  }
  
  button.btn-primary {
    background-color: #444 !important;
    border: none !important;
  }
  
  button.btn-primary:hover {
    background-color: #555 !important;
  }
  
  button.btn-secondary {
    background-color: #666 !important;
    border: none !important;
  }
  
  button.btn-secondary:hover {
    background-color: #777 !important;
  }
  
  /* Media query for smaller screens (1024px to 1440px) */
  @media (min-width: 1024px) and (max-width: 1275px) {
    .division-buttons button:nth-child(1) {
      width: 120%;
    }
  }
  
  /* Media query for mobile screens */
  @media (max-width: 1024px) {
    .division-table th, .division-table td {
      padding: 10px;
      font-size: 12px;
    }
  
    .division-buttons {
      flex-direction: column;
    }
  
    .division-buttons button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  