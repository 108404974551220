/* ASTMModal.css */

.astm-modal .modal-content {
    background-color: #222; /* Dark background for consistency */
    color: #fff; /* White text */
    border-radius: 20px; /* Rounded corners */
    border: 1px solid #666; /* Light border for separation */
    padding: 20px; /* Padding inside the modal */
  }
  
  .astm-modal .modal-header {
    border-bottom: 1px solid #666; /* Border between header and body */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .astm-modal .modal-header .modal-title {
    font-size: 24px; /* Title font size */
    color: #fff; /* White title text */
  }
  
  .astm-modal .modal-header .close {
    color: #fff; /* White close button */
    opacity: 1;
  }
  
  .astm-modal .modal-body {
    font-size: 16px; /* Standard font size for modal content */
    line-height: 1.6; /* Increased line-height for readability */
  }
  
  .astm-modal .standard-logo {
    width: 80px; /* Set width for the logo image */
    float: right; /* Float the logo to the right */
    margin-left: 20px; /* Space between logo and text */
  }
  
  .astm-modal h5 {
    font-size: 18px; /* Font size for section titles */
    margin-top: 20px; /* Space above each section */
    color: #f0f0f0; /* Slightly lighter text color for headings */
  }
  
  .astm-modal ul {
    list-style-type: disc; /* Disc-style list markers */
    padding-left: 20px; /* Space for the list indent */
  }
  
  .astm-modal ul li {
    margin-bottom: 10px; /* Space between list items */
  }
  
  .astm-modal hr {
    border-top: 1px solid #444; /* Light horizontal line */
    margin: 20px 0; /* Space above and below the line */
  }
  