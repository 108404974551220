.home-container {
  color: #fff;
  /* height: 100vh; */
  padding: 20px;
  overflow: hidden;
}


.scrollable-cards {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 15px;
}

.scrollable-cards .card {
  display: inline-block;
  width: calc(100% - 40px);
  margin-right: 20px;
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
}

.scrollable-cards .card.highlighted {
  transform: scale(1.05);
  border: 2px solid #f0f;
}

.progress-card .progress-percentage {
  font-size: 24px;
  font-weight: bold;
  color: #f0f;
  text-align: right;
}

.home-divider {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(90deg, #FFFFFF 59.5%, rgba(153, 153, 153, 0) 100%);
  /* margin-bottom: 20px; */
}

.tasks-cards {
  display: flex;
  gap: 10px; /* Space between the two cards */
}

.task-card {
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.highlighted-card {
  box-shadow: 0 0 20px rgba(140, 78, 255, 0.5); /* Soft purple glow */
  border: 1px solid rgba(140, 78, 255, 0.7); /* Purple border */
}

.dimmed-card {
  background: linear-gradient(to right, #06060700, #070708);
  border-radius: 50px !important;
  filter: grayscale(100%); /* Grayscale the second card */
  transition: all 0.3s ease-in-out;
}

.home-highlighted-text {
  color: #8a2be2; /* Purple color for highlighted text */
}

.task-due {
font-family: FONTSPRING DEMO - neue Singular D Light;
font-size: 12px;
font-weight: 320;
line-height: 15.14px;
letter-spacing: 0.1em;
text-align: left;

}

.task-completed {
  color: rgba(255, 255, 255, 0.5); /* Lighter for completed text */
  font-family: FONTSPRING DEMO - neue Singular D Light;
font-size: 12px;
font-weight: 320;
line-height: 15.14px;
letter-spacing: 0.1em;
text-align: left;
}

.task-arrow {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.task-arrow:hover {
  transform: translateX(5px); /* Hover effect to move arrow */
}

.task-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}


.highlighted-card {
  border: 1px solid #a64dff; /* Purple border for the highlighted card */
  color: #fff;
  border-radius: 50px !important;
  /* box-shadow: 0 0 15px rgba(166, 77, 255, 0.5); */
  box-shadow: 0px 0px 10px 0px #8A34FF;

}

.task-card .task-due {
  color: #969696;
  margin-top: 10px;
}

.updates-section {
  margin-bottom: 20px;
}

.section-header h5 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
}

.section-header hr {
  border-top: 1px solid #fff;
}

.updates-cards {
  display: flex;
  gap: 10px; /* Space between the two cards */
}

.update-card {
  background-color: #1c1c1c;
  border-radius: 50px !important;
  padding: 15px;
  flex: 1;
  border: 1px solid transparent;
  position: relative;
  transition: all 0.3s ease;
  color: #999;
}


.update-header {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}

.user-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.timestamp {
  color: #666;
  font-size: 12px;
  /* text-align: end; */
  margin-left: 20px;
}

.update-title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.update-message {
  color: #ccc;
  margin-bottom: 15px;
}

.update-footer {
  color: #666;
  font-size: 12px;
}


.progress-section {
  margin-bottom: 20px;
}

.section-header h5 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
}

.section-header hr {
  border-top: 1px solid #fff;
}

.progress-cards {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.progress-card {
  background-color: #1c1c1c;
  border-radius: 50px !important;
  padding: 15px;
  flex: 1;
  border: 1px solid transparent;
  margin-bottom: 15px;
  color: #999;
  position: relative;
  transition: all 0.3s ease;
}



.progress-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-details ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.progress-details ul li {
  color: #fff;
  font-size: 14px;
}

.home-progress-circle {
  width: 60px;
  height: 60px;
  border: 3px solid #a64dff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.progress-update {
  color: #666;
  font-size: 12px;
  margin-top: 10px;
}

.progress-arrow {
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
}

.image-container {
  position: relative;
  width: 100%; /* Make the container responsive */
  max-width: 500px; /* Set a max width for the image */
  margin: 0 auto; /* Center align the container */
  right: 45%;
  bottom: 14%;
}

/* Responsive image */
.astronaut-img {
  width: auto;
  height: auto; /* Maintain aspect ratio */
  display: block;
  z-index: 10;
  position: relative; /* Required for z-index to take effect */
}

.home-circle {
  width: 20px;
  height: 20px;
  background-color: #a64dff;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}

.helmet-circle {
  top: 20%;
  right: -12%;
  z-index: 100;
}

.chest-circle {
  top: 37%;
  right: 1%;
  z-index: 100;
}

.gloves-circle {
  top: 55%;
  right: 23%;
  z-index: 100;
}

.boots-circle {
  top: 91%;
  right: 0%;
  z-index: 100;
}

.additional-circle-0 {
  top: 18%;
  right: 5%;
  z-index: 100;
}

.additional-circle-1 {
  top: 32%;
  left: 140%;
  z-index: 100;
}

.additional-circle-2 {
  top: 49%;
  left: 106%;
  z-index:100;
}

.additional-circle-3 {
  top: 55%;
  left: 140%;
  z-index: 100;
}

.additional-circle-4 {
  bottom: 27%;
  left: 95%;
  z-index: 100;
}

.additional-circle-5 {
  bottom: 27%;
  left: 115%;
  z-index: 100;
}

.additional-circle-6 {
  bottom: 7%;
  left: 118%;
  z-index: 100;
}


.additional-circle-7 {
  bottom: 52%;
  left: 66%;
  z-index: 100;
}

/* .home-eclipse-circle {
  position: absolute;
  bottom: 20px; 
  right: -250px;
  width: 400px;  
  height: 100px;  
  border-radius: 50%;
  border: 1px solid #FFFFFF;
} */

.home-eclipse-circle {
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 400px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #fff;
  transform: translateX(20%);
  rotate: 45%;
}

.home-square-box {
  width: 43.23px;
  height: 43.23px;
  float: right;
  gap: 0px;
  border-radius: 7px 7px 7px 7px;
  opacity: 0px;
  border: 1.5px solid #000000;
  background: #DCDCDC;
  transform: rotate(45deg);
}

.home-rotated-icon {
  transform: rotate(310deg); /* Apply the desired rotation to the SVG */
  padding: 1px;
  margin: 10px;
  color: #000000;
}


@media (min-width: 1200px) and (max-width: 1800px) {

  .home-container {
    color: #fff;
    
    padding: 20px;
    overflow: hidden;
  }
  
}

