.quality-testing-container {
    padding: 20px;
    background-color: #0A0A0A;
    color: #fff;
    min-height: 100vh;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    
  }
  
  .menu-icon {
    font-size: 0.5em;
    cursor: pointer;
  }
  
  .quality-testing-section-divider {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, #FFFFFF 59.5%, rgba(153, 153, 153, 0) 100%);
    margin-bottom: 20px;
}
  .test-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .option-card {
    flex: 1;
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-right: 10px;
    transition: background-color 0.3s ease;
    width: 230px;
    height: 288px;
  }
  
  .option-card:last-child {
    margin-right: 0;
  }
  
  .option-card i {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .option-card:hover {
    background-color: #333;
  }
  
  .previous-tests {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .image-card {
    position: relative;
    flex: 1 1 calc(50% - 10px);
    overflow: hidden;
    border-radius: 15px;
    width: 236px;
    height: 133px;
  }
  
  .test-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
  }
  
  .image-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  
  .image-text {
    color: #fff;
    font-size: 1em;
    text-align: center;
  }
  
  .tests-section {
    margin-top: 40px;
  }
  
  .quality-table-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
  .quality-table-actions button {
    width: 100px;
    border-radius: 50px;
  }
  .tests-search {
    width: 200px;
    height: 35px;
    background-color: #333;
    border: 1px solid #444;
    border-radius: 20px;
    color: #fff;
  }
  
  .tests-table {
    background-color: #1a1a1a;
    color: #fff;
    border: 1px solid #444;
  }
  
  .tests-table th,
  .tests-table td {
    border: none;
    padding: 15px;
    background-color: #1a1a1a;
    color: #d2d2d2;
  }
  
  .sub-item td {
    padding-left: 30px;
  }
  
  .inner-item td {
    padding-left: 60px;
  }
  