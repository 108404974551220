.progress-container {
    display: flex;
    justify-content: space-between; /* Ensure equal spacing between circles */
    align-items: center;
    width: 100%; /* Ensure the progress bar takes the full width of the container */
    margin: 20px 0;
  }
  
  .progress-step {
    display: flex;
    flex-direction: column; /* Stack circles and labels vertically */
    align-items: center;
    position: relative;
    flex: 1; /* Ensure that each step takes an equal amount of space */
  }
  
  .progressbar-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: grey;
    cursor: pointer;
    z-index: 2; /* Ensure circles appear above the lines */
  }
  
  .progressbar-circle.active {
    background-color: purple;
  }
  
  .stage-label {
    margin-top: 10px;
    font-size: 12px;
    color: grey;
    text-align: center;
  }
  
  .stage-label.active-label {
    color: white;
  }
  
  .progress-line {
    position: absolute;
    top: 10px; /* Align to the middle of the circle */
    left: 100%; /* Start from the center of the circle */
    width: 100%; /* Extend to the next circle */
    height: 2px;
    background-color: grey;
    transform: translateX(-50%);
    z-index: 1; /* Ensure the line is behind the circles */
  }
  
  .progress-line.active-line {
    background-color: purple;
  }
  