/* X-EvasModal.css */

.x-evas-modal .modal-content {
    background-color: #222;
    color: #fff;
    border-radius: 20px;
    border: 1px solid #666;
    padding: 20px;
  }
  
  .x-evas-modal .modal-header {
    border-bottom: 1px solid #666;
  }
  
  .x-evas-modal .modal-header .modal-title {
    font-size: 24px;
    color: #fff;
  }
  
  .x-evas-modal .modal-header .close {
    color: #fff;
    opacity: 1;
  }
  
  .x-evas-modal .modal-body {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .x-evas-modal .nasa-logo {
    width: 80px;
    float: right;
    margin-left: 20px;
  }
  
  .x-evas-modal h5 {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .x-evas-modal ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .x-evas-modal ul li {
    margin-bottom: 10px;
  }
  