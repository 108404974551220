.design-container {
    padding: 20px;
    background-color: #0A0A0A;
    color: #fff;
    min-height: 100vh;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .menu-icon {
    font-size: 1.5em;
    cursor: pointer;
  }

  .menu-icon .btn:hover {
    background: #0A0A0A !important;
  }
  
  .section-divider {
    width: 100%;
    height: 2px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  
  .previous-work {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .inspiration {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .image-card {
    position: relative;
    flex: 1 1 calc(50% - 10px);
    overflow: hidden;
    border-radius: 15px;
  }
  
  .inspiration .image-card {
    flex: 1 1 calc(33.33% - 10px); /* Adjust for three images per row */
  }
  
  .work-image,
  .inspiration-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
  }
  
  .image-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  
  .image-text {
    color: #fff;
    font-size: 1em;
    text-align: center;
  }
  
  .assets-section {
    margin-top: 40px;
  }
  
  .design-table-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .design-table-actions button {
    border-radius: 20px;
  }

  .design-table-actions  .btn:hover {
    background: #0A0A0A !important;
  }
  
  .design-table-actions .assets-search {
    width: 200px !important;
    background-color: #333 !important;
    border: 1px solid #444 !important;
    border-radius: 20px !important;
    color: #fff !important;
  }

  .design-table-data .table-responsive {
    /* overflow-x: auto; */
    height: 400px;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    scrollbar-width: thin; /* Firefox scrollbar width */
    scrollbar-color: #454545 #0A0A0A; /* Scrollbar color for Firefox */

  }
  
  /* Custom scrollbar styling for WebKit browsers (Chrome, Safari, etc.) */
   .table-responsive::-webkit-scrollbar {
    width: 8px;
  }
  
   .table-responsive::-webkit-scrollbar-track {
    background: #0A0A0A; /* Scrollbar track background */
  }
  
   .table-responsive::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb */
    border-radius: 10px; /* Rounded scrollbar */
    border: 2px solid #0A0A0A; /* Adds padding around the thumb */
  }
  
  .design-table-data .table-responsive::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker thumb on hover */
  }


  
  .design-table {
    background-color: #1a1a1a;
    width: 100%;
    color: #fff;
    border: 2px solid #333232;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .design-table tbody tr:hover {
    background: linear-gradient(90deg, #0D0D0D 0%, #717171 100%) !important;
    cursor: pointer;
  }
  
  .design-table tbody tr:hover td {
    background: transparent !important; /* Remove the background from td to avoid conflict */
  }
  
  .design-table th,
  .design-table td {
    padding: 15px;
    background-color: #1a1a1a;
    color: #d2d2d2 !important;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #444;
    font-family: FONTSPRING DEMO - neue Singular D Light;
    font-size: 14px;
    font-weight: 320;
    line-height: 17.66px;
    letter-spacing: 0.1em;
    text-align: left;
  }
  
  .design-table th {
    background-color: #333;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }
  
  .design-table tr:last-child td {
    border-bottom: none;
  }
  
  .sub-item td {
    padding-left: 30px;
  }
  
  .inner-item td {
    padding-left: 60px;
  }
  
  .creator-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .creator-badge {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .design-custom-checkbox {
    appearance: none; /* Remove default checkbox style */
    width: 16px;
    height: 16px;
    border: 2px solid #555; /* Dark border */
    background-color: #222; /* Dark background */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer;
    position: relative;
  }
  
  /* When the checkbox is checked */
  .design-custom-checkbox:checked {
    background-color: #555; /* Change background when checked */
  }
  
  /* Custom checkmark for checked state */
  .design-custom-checkbox:checked::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 5px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  /* Hover effect */
  .design-custom-checkbox:hover {
    border-color: #888; /* Lighter border on hover */
  }
  
  /* Focus outline */
  .design-custom-checkbox:focus {
    outline: none;
    box-shadow: 0 0 3px 2px rgba(255, 255, 255, 0.2); /* Focused outline */
  }
  
  
  .design-divider {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, #FFFFFF 59.5%, rgba(153, 153, 153, 0) 100%);
  }