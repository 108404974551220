.modal-container {
  position: relative;
}

.vyomgloves-custom-modal .modal-content {
  background-color: #1c1c1c;
  color: #fff;
  border-radius: 15px;
  border: none;
  width: 530px;
  box-shadow: none;
}

.modal-title {
  color: #a64dff;
  font-weight: bold;
}

.modal-body {
  padding: 20px;
}

.gloves-content {
  padding: 20px;
}

.gloves-description {
  font-size: 14px;
  margin-bottom: 20px;
  color: #ccc;
}

.section-title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #fff;
}

.features-list {
  list-style: none;
  padding: 0;
  color: #ccc;
  margin-bottom: 20px;
}

.features-list li {
  margin-bottom: 5px;
}

.progress-section {
  margin-top: 20px;
}

/* Circular progress bar styling */
.progress-circle {
  width: 80px;
  height: 80px;
  border: 4px solid #a64dff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
}

/* Progress details styling */
.progress-details {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.progress-details li {
  color: #ccc;
  margin-bottom: 5px;
}

/* Progress assignments table styling */
.progress-assignments {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.progress-assignments th,
.progress-assignments td {
  color: #fff;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.progress-assignments th {
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}

/* Status dots styling */
.status-dot {
  width: 10px;
  height: 10px;
  background-color: #a64dff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.assigned-to {
  color: #a64dff;
}

/* Right-angle arrow icon */
.arrow-icon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.arrow-icon:hover {
  color: #a64dff;
}

/* View more button */
.view-more {
  color: #a64dff;
  text-decoration: underline;
  cursor: pointer;
}

/* Modal positioning */
.vyomgloves-custom-modal {
  position: absolute;
  top: 2%; /* Adjust this based on where the modal should appear vertically */
  left: 15%; /* Adjust this to align the modal horizontally */
  transform: translate(-50%, 0);
}

/* Connecting line between the gloves circle and the modal */
.vyomgloves-custom-modal::before {
  content: '';
  position: absolute;
  top: 45%; /* Adjust to the correct position to link with the circle */
  left: -170px; /* Adjust to connect the line from the modal to the circle */
  width: 100%;
  height: 2px;
  background-color: #a64dff;
  transform: translateY(-50%);
}
