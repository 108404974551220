.bill-of-materials-container {
    padding: 20px;
    color: #fff;
}

.top-navigation {
    margin-bottom: 10px;
}

.breadcrumb-bill-of-materials {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 8%;
    font-size: 18px;
  }
  
  .breadcrumb-item-bill-of-materials {
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }
  
  .breadcrumb-item-bill-of-materials.active {
    background-color: #fff;
    color: #000;
  }
  
  .breadcrumb-arrow-bill-of-materials {
    position: relative;
  }
  
  .routing-arrow-icon {
    display: inline-block;
    width: 300px; /* Set the fixed width */
    height: 0px; /* No height since it's a border line */
    border-top: 1px solid rgba(255, 255, 255, 0.7); /* Set the line color and opacity */
    position: relative;
    top: 10px; /* Adjust to position the line correctly */
    right: 50px;
  }
  
  .routing-arrow-icon::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0; /* Positions the arrow on the right end of the line */
    top: -4px; /* Adjust the arrow's vertical position */
    width: 0;
    height: 0;
    border-left: 6px solid rgba(255, 255, 255, 0.7); /* Arrow triangle shape */
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }

.production-form-bill {
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #333;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    font-size: 14px;
    color: #bbb;
}

.production-form-bill .form-input {
    background-color: #333 !important;
    color: #fff !important;
    border: 1px solid #555 !important; 
    border-radius: 20px !important;
    padding: 10px !important;
}

.form-input::placeholder {
    color: #666;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.clear-button,
.save-button {
    width: 45%;
    background-color: #444;
    border-color: #444;
    border-radius: 20px;
    padding: 10px;
}

.save-button {
    background-color: #8c8c8c;
    color: #000;
}

.clear-button:hover,
.save-button:hover {
    background-color: #999;
    border-color: #999;
}

.finished-products-container {
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #333;
}

.section-title {
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
}

.product-details p {
    font-size: 14px;
    color: #bbb;
    margin-bottom: 20px;
}

.finished-products-table {
    border: 1px solid #333;
}

.table-header {
    font-size: 14px;
    color: #bbb;
    background-color: #2c2c2c;
    border: none;
}

.table-cell {
    color: #ccc;
    border: none;
}

.quota-cell {
    text-align: right;
}

.table-cell:first-child {
    font-weight: bold;
}

.costs-table {
    background-color: #1c1c1c;
    color: #fff;
    border-radius: 15px;
}


.operation-costs {
    background-color: transparent; /* Ensure transparency for the container */
    color: #fff;
  }
  
  .section-title {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 15px;
    text-align: left;
  }
  
  .costs-table-container {
    background-color: #1c1c1c;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #333;
  }
  
  .costs-table {
    width: 100%;
    margin-bottom: 0;
    border-collapse: separate;
    border-spacing: 0 10px; /* Adds spacing between rows */
  }
  
  .table-header {
    font-size: 14px;
    color: #888;
    background-color: transparent;
    border: none;
  }
  
  .table-row {
    background-color: #2d2d2d;
    border-radius: 10px;
  }
  
  .table-cell {
    padding: 10px 15px;
    color: #ccc;
    border: none;
  }
  
  .table-cell:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .table-cell:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: right; /* Align cost values to the right */
  }
  

  .finished-products-container-bill-of-materials {
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #333;
  }