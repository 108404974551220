.quality-testing-manual-container {
    /* padding: 10px; */
    color: #fff;
    background-color: #000;
    font-family: Arial, sans-serif;
  }
  
  .back-button-container {
    margin-bottom: 20px;
  }
  
  .back-button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
  
  .form-title {
    margin-bottom: 20px;
  }
  
  .row {
    display: flex;
  }
  
  .form-section {
    padding: 20px;
    border: 1px solid #333;
    border-radius: 10px;
  }
  
  .form-section h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #aaa;
    text-transform: uppercase;
  }
  
  .quality-form {
    display: flex;
    flex-direction: column;
  }
  
  .quality-form label {
    margin-bottom: 5px;
    font-size: 8px;
    color: #bbb;
    text-transform: uppercase;
  }
  
  .manually-custom-input {
    background: none !important;
    border: none !important;
    border-bottom: 1px solid #555 !important;
    color: #fff !important;
    margin-bottom: 10px !important;
    font-size: 12px !important;
  }
  
  .manually-custom-input:focus {
    outline: none !important;
    border-bottom: 1px solid #fff !important;
  }
  
  .form-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .form-buttons .btn {
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 14px;
  }
  
  .save-draft-button {
    background-color: transparent;
    color: #bbb;
    border: 1px solid #bbb;
    border-radius: 20px;
  }
  
  .save-button {
    background-color: #fff;
    color: #000;
    border-radius: 20px;
  }
  
  .save-button:hover, .save-draft-button:hover {
    opacity: 0.8;
  }
    
  .file-upload-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .file-upload-box {
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 30px;
    border: 2px dashed #555;
    border-radius: 10px;
  }
  
  .file-upload-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .file-upload-icon {
    font-size: 40px;
    /* margin-bottom: 20px; */
  }
  
  .accepted-formats {
    font-size: 24px;
    color: #bbb;
  }
  