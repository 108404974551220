/* NestedTable.css */

.nested-table-container {
  background-color: #1E1E1E;
  border-radius: 10px;
  padding: 15px;
  color: #fff;
  font-size: 14px;
  overflow: hidden;
}

.nested-table {
  width: 100%;
  color: #fff;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden;
}

.nested-table th,
.nested-table td {
  padding: 15px;
  border: none;
}

.nested-table th {
  text-transform: uppercase;
  color: #bbb;
  font-weight: normal;
  font-size: 12px;
  background-color: #333;
  border-bottom: 1px solid #444;
}

.nested-table td {
  background-color: #333;
  border-bottom: 1px solid #444;
}

.nested-table tr:last-child td {
  border-bottom: none;
}

.nested-table .level-1 td {
  padding-left: 20px;
}

.nested-table .level-2 td {
  padding-left: 40px;
}

.nested-table .level-3 td {
  padding-left: 60px;
}

.expand-icon {
  cursor: pointer;
  color: #aaa;
}

.creators {
  display: flex;
  align-items: center;
  gap: 10px;
}

.creator-badge {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  border: 2px solid;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  gap: 8px;
}

.creator-avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -14px;
}
