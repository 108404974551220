.top-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #000; */
    color: #fff;
    padding: 10px 20px;
    border-right: 6px solid #333; /* Adds a thick border at the end of the navbar */
}

.top-navbar .logo h1 {
    margin: 0;
    font-size: 24px;
}

.top-navbar-right .toggle-button {
    /* background-color: #333; */
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.header-route-name {
font-family: FONTSPRING DEMO - neue Singular D Light;
font-size: 24px;
font-weight: 320;
line-height: 30.28px;
letter-spacing: 0.1em;
text-align: left;

}