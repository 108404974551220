.register-container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: black; */
    overflow: hidden;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/login/images/image-1.png');
    background-size: cover;
    background-position: center;
    z-index: -2; /* Ensure it's behind everything */
    filter: brightness(1) contrast(100) saturate(1000); 
    /* filter: brightness(0.9) contrast(7.9) saturate(10) hue-rotate(45deg); */
    width: Fixed (1,439.81px)px;
    height: Fixed (885.72px)px;
    gap: 0px;
  /* opacity: 0px; */
  }
  
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(359deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../assets/login/images/image-2.png');
    background-blend-mode: hard-light;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
  
  .shiny-glow {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    border-radius: 50% 50% 0 0; 
    height: 350px; 
    background: radial-gradient(
        ellipse at center,
        rgba(140, 78, 255, 0.8), 
        transparent 70%
    );
    pointer-events: none;
    z-index: 1;
  }
  
  .register-container > .container {
    z-index: 2;
  }
  
  .logo {
    margin-bottom: 20px;
    position: relative;
    right: 50%;
  }
  
  .tagline {
    margin-bottom: 40px;
    font-size: 16px;
  }
  
  .input-field {
    background-color: #333;
    color: white;
    border: 1px solid #555;
    border-radius: 20px; /* Adjusted to match rounded corners */
    padding: 12px 20px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); /* Subtle inner shadow */
  }
  
  .input-field::placeholder {
    color: #999;
  }
  
  .register-button-image {
    filter: brightness(6) contrast(0.5) saturate(100); 
  }
  
  .register-button-container {
    position: relative;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .register-button-image {
    width: 150px; /* Adjust the width according to your design */
    height: 50px; /* Adjust the height according to your design */
    filter: brightness(1.5) contrast(1.2) saturate(5);
    border-radius: 20px; /* Rounded corners */
    box-shadow: 0 0 15px rgba(140, 78, 255, 0.8); /* Glowing effect */
  }
  
  .register-button-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    pointer-events: none; /* Ensures the text is not clickable */
  }
  
  .register-button:hover {
    background-color: #a86bff !important;
    box-shadow: 0 0 20px rgba(168, 107, 255, 0.7) ;
  }
  
  .error-message {
    color: #ff4d4d;
    margin-bottom: 10px;
    font-size: 14px;
  }
  