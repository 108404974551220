.order-container {
    padding: 20px;
    background-color: #0A0A0A; /* Dark background color */
    color: #fff;
    min-height: 100vh; /* Full screen height */
  }
  
  .breadcrumb-order {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 8%;
    font-size: 18px;
  }
  
  .breadcrumb-item-order {
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }
  
  .breadcrumb-item-order.active {
    background-color: #fff;
    color: #000;
  }
  
  .breadcrumb-arrow-order {
    position: relative;
  }
  
  .order-arrow-icon {
    display: inline-block;
    width: 300px; /* Set the fixed width */
    height: 0px; /* No height since it's a border line */
    border-top: 1px solid rgba(255, 255, 255, 0.7); /* Set the line color and opacity */
    position: relative;
    top: 10px; /* Adjust to position the line correctly */
    right: 50px;
  }
  
  .order-arrow-icon::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0; /* Positions the arrow on the right end of the line */
    top: -4px; /* Adjust the arrow's vertical position */
    width: 0;
    height: 0;
    border-left: 6px solid rgba(255, 255, 255, 0.7); /* Arrow triangle shape */
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
  
  .order-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .order-actions button {
    background-color: #333;
    color: #fff;
    border-radius: 20px;
    border: 1px solid #D2D2D2;
    padding: 10px 20px;
  }
  
  .order-search {
    background-color: #333 !important;
    color: #fff !important;
    border: 1px solid #D2D2D2;
    border-radius: 20px !important;
    padding: 10px;
    width: 400px; /* Adjusted for better alignment */
  }
  
  /* .orders-table  */

  .order-table {
    background-color: #1a1a1a;
    width: 100%;
    color: #fff;
    border: 2px solid #333232;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .order-table tbody tr:hover {
    background: linear-gradient(90deg, #0D0D0D 0%, #717171 100%) !important;
    cursor: pointer;
  }
  
  .order-table tbody tr:hover td {
    background: transparent !important; /* Remove the background from td to avoid conflict */
  }
  
  .order-table th,
  .order-table td {
    padding: 15px;
    background-color: #1a1a1a;
    color: #d2d2d2 !important;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #444;
    font-family: FONTSPRING DEMO - neue Singular D Light;
    font-size: 14px;
    font-weight: 320;
    line-height: 17.66px;
    letter-spacing: 0.1em;
    text-align: left;
  }
  
  .order-table th {
    background-color: #333;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }
  
  .order-table tr:last-child td {
    border-bottom: none;
  }
  
  .status.started {
    color: #FFD700; /* Yellow for "Started" */
  }
  
  .status.finished {
    color: #32CD32; /* Green for "Finished" */
  }

  .profile-border {
    border-radius: 20px;
  }

  .profile-border.started {

    border: 1px  solid #FFD700;
  }

  .profile-border.finished {

    border: 1px solid #32CD32 ;
  }
  
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }
  