.maintenance-container {
    color: #fff;
    padding: 20px;
    overflow: hidden;
    height: 100vh; /* Ensures the container takes up the full height of the viewport */
  }
  
  .maintenance-form, .service-history {
    background-color: #2d2d2d;
    padding: 20px;
    border-radius: 15px;
  }
  
  .maintenance-form {
    margin-bottom: 20px;
  }
  
  .maintenance-calendar {
    padding-left: 20px;
    background-color: #2d2d2d;
    border-radius: 15px;
  }
  
  .h-100 {
    height: 100%;
  }
  
  .d-flex {
    display: flex;
  }
  
  .flex-column {
    flex-direction: column;
  }
  
  .flex-grow-1 {
    flex-grow: 1;
  }
  