.calendar-container {
  color: #fff;
  padding: 20px;
  background-color: #282c34;
  height: 100vh;
  border-radius: 15px;
}

h3 {
  font-size: 28px;
  color: #61dafb;
  margin-bottom: 20px;
}

.fc-theme-standard {
  background-color: #060607;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.fc-scrollgrid.fc-scrollgrid-liquid {
  border: none;
}

.fc-theme-standard td {
  border-right: none;
  border-bottom: none;
}

.fc-theme-standard th {
border-right: none;
}

.fc-col-header-cell-cushion {
color: #fff;
}

.fc-daygrid-day-number {
text-decoration: none;
}

.fc-dayGridMonth-view {
  height:1,213px;
  gap: 0px;
  border-radius: 15px 15px 15px 15px;
  border: 1px 0px 0px 0px;
  opacity: 1; /* Fix opacity to ensure calendar visibility */
  box-shadow: 0px 0px 10px 0px #FFFFFFBF inset;
  /* border: 1px solid #444; */
}

.fc-header-toolbar {
  color: #bbb;
  font-weight: normal;
  font-size: 16px;
  border-bottom: 1px solid #444;
}

.rbc-date-cell {
  color: #ccc;
}

.rbc-event {
  background-color: #61dafb;
  color: #282c34;
  border-radius: 5px;
  padding: 5px;
}

.rbc-today {
  background-color: #333;
}

.rbc-off-range-bg {
  background-color: #444;
}

/* Custom style for the current date */
.rbc-today span {
  color: #ff6347 !important; /* Highlight today's date in a different color */
}
