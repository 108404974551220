  
 
  
  .sidebar-title {
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 30px;
    border-bottom: 1px solid #555;
    padding-bottom: 10px;
  }
  
  .sidebar-content {
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #555 transparent; /* For Firefox */
  }
  
  .sidebar-content::-webkit-scrollbar {
    width: 6px;
  }
  
  .sidebar-content::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
  }
  
  .sidebar-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0; /* Larger padding for better spacing */
    border-bottom: 1px solid #2e2e2e;
    font-size: 16px;
    align-items: center; /* Vertically center the text */
  }
  
  .sidebar-item:last-child {
    border-bottom: none;
  }
  
  .label {
    font-size: 14px; /* Slightly smaller for label */
    color: #ccc; /* Light gray for labels */
    flex: 1;
    padding-right: 10px; /* Add space between label and value */
  }
  
  .value {
    font-size: 16px; /* Slightly larger for value */
    color: #ddd; /* Slightly brighter for values */
    flex: 1;
    text-align: right; /* Right align the values */
  }
  
  .status-pass .value {
    color: #32cd32; /* Green for 'Pass' */
  }
  
  /* Scrollbar styling */
  .sidebar-content::-webkit-scrollbar {
    width: 6px;
  }
  
  .sidebar-content::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
  }
  
  .sidebar-item:last-child {
    border-bottom: none; /* Remove border from last item */
  }
  