.requirements-container {
  padding: 20px;
  background-color: #0A0A0A;
  color: #fff;
  min-height: 100vh;
}

.top-section h4 {
  margin-bottom: 20px;
}

.requirement-divider {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(90deg, #FFFFFF 59.5%, rgba(153, 153, 153, 0) 100%);
  margin-bottom: 20px;
}

.requirements-search {
  width: 200px !important;
  background-color: #333 !important;
  border: 1px solid #444 !important;
  border-radius: 20px !important;
  color: #fff !important;
  margin: 0 10px !important;
}

.requirements-table {
  background-color: #1a1a1a;
  width: 100%;
  color: #fff;
  border: 2px solid #333232;
  border-radius: 10px;
  overflow: hidden;
}

.requirements-table tbody tr:hover {
  background: linear-gradient(90deg, #0D0D0D 0%, #717171 100%) !important;
  cursor: pointer;
}

.requirements-table tbody tr:hover td {
  background: transparent !important; /* Remove the background from td to avoid conflict */
}



.requirements-table th,
.requirements-table td {
  padding: 15px;
  background-color: #1a1a1a;
  color: #d2d2d2 !important;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #444;
  font-family: FONTSPRING DEMO - neue Singular D Light;
  font-size: 14px;
  font-weight: 320;
  line-height: 17.66px;
  letter-spacing: 0.1em;
  text-align: left;

}

.requirements-table th {
  background-color: #333;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.requirements-table tr:last-child td {
  border-bottom: none;
}

.sub-item td {
  padding-left: 30px;
}

.inner-item td {
  padding-left: 60px;
}


.requirement-custom-checkbox {
  appearance: none; /* Remove default checkbox style */
  width: 16px;
  height: 16px;
  border: 2px solid #555; /* Dark border */
  background-color: #222; /* Dark background */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  position: relative;
}

/* When the checkbox is checked */
.requirement-custom-checkbox:checked {
  background-color: #555; /* Change background when checked */
}

/* Custom checkmark for checked state */
.requirement-custom-checkbox:checked::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Hover effect */
.requirement-custom-checkbox:hover {
  border-color: #888; /* Lighter border on hover */
}

/* Focus outline */
.requirement-custom-checkbox:focus {
  outline: none;
  box-shadow: 0 0 3px 2px rgba(255, 255, 255, 0.2); /* Focused outline */
}


.creator-cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.creator-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.requirement-buttons button {
  border-radius: 20px;
}

.requirement-buttons .btn-outline-light {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.requirement-buttons .btn-outline-light img {
  max-width: 100%;
  height: auto;
}

/* Media query for smaller screens (1024px to 1440px) */
@media (min-width: 1024px) and (max-width: 1275px) {
  .requirement-buttons button:nth-child(1) {
    width: 120%;
  }

  .requirements-search {
    width: 180px !important;
  }
}

/* Media query for mobile screens */
/* @media (max-width: 1024px) {
  .requirements-table th, .requirements-table td {
    padding: 10px;
    font-size: 12px;
  }

  .creator-avatar {
    width: 25px;
    height: 25px;
  }

  .creator-cell {
    gap: 5px;
  }

  .requirement-buttons {
    flex-direction: column;
  }

  .requirement-buttons button {
    width: 100%;
    margin-bottom: 10px;
  }

  .requirements-search {
    width: 100% !important;
    margin: 10px 0;
  }
} */
