.safety-requirement-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #0A0A0A;
  color: #fff;
}

.safety-requirement-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  /* background-color: #1E1E1E; */
}

.safety-requirement-top-bar-left {
  display: flex;
  align-items: center;
}

.safety-requirement-top-bar-right {
  display: flex;
  align-items: center;
}

.top-bar-button {
  margin-left: 10px;
  background-color: transparent;
  border: 1px solid #555;
  color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
  gap: 10px;
}

.search-bar input {
  background-color: #333;
  border: 1px solid #555;
  color: #fff;
  border-radius: 20px;
  padding: 5px 15px;
}


.tree-container {
  width: 100%;
  height: 100vh;
  background-color: #0A0A0A;
}

.custom-path.red {
  stroke: red;
  stroke-width: 2px;
}

.custom-path.yellow {
  stroke: yellow;
  stroke-width: 2px;
}

.custom-path.green {
  stroke: green;
  stroke-width: 2px;
}

.custom-path.blue {
  stroke: blue;
  stroke-width: 2px;
}

.custom-path.purple {
  stroke: purple;
  stroke-width: 2px;
}

.custom-path.default-path {
  stroke: #555; /* Default path color */
  stroke-width: 2px;
}

.rd3t-link {
  fill: none;
  stroke: #fff !important;
}

.rd3t-node {
  cursor: pointer;
  stroke: #fff !important;
  stroke-width: 0px !important;
}

.rd3t-leaf-node {
  cursor: pointer;
  fill: transparent;
  stroke: #fff !important;
  stroke-width: 0 !important;
}




/* SafetyRequirement.css */

.safety-requirement-custom-modal .modal-title-container {
  display: flex;
  justify-content: space-between;
  width: 60vw;
}

.safety-requirement-custom-modal .modal-content {
  width: 100vw;
  /* background: linear-gradient(270deg, rgba(6, 6, 6, 0.7) 0%, rgba(13, 13, 13, 0.7) 100%); */
  background: 
      linear-gradient(
        270deg, rgba(6, 6, 6, 0.7) 0%, rgba(13, 13, 13, 0.7) 100%
      ),
      url('../../../../assets/admin/requirement/rectangle-box.png');
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 4px 0px #FFFFFF80 inset;
  border: 0.5px solid #FFFFFF;
  font-family: FONTSPRING DEMO - neue Singular D Light;
  font-size: 14px;
  font-weight: 320;
  line-height: 17.66px;
  letter-spacing: 0.1em;
  text-align: left;

}

.safety-requirement-custom-modal .modal-title-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.safety-requirement-custom-modal .modal-title-completion {
  font-size: 1rem;
  color: #FFD700;
  align-self: center;
  position: absolute;
  right: 50%;
}

.modal-content-container {
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.modal-header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: #A9A9A9;
}

.modal-row {
  display: flex;
}

.modal-left-col, .modal-right-col {
  flex: 1;
}

.modal-vertical-line {
  width: 2px;
  background-color: #555;
  margin: 0 50px;
}

.modal-assigned-section {
  margin-bottom: 20px;
}

.assigned-users {
  display: flex;
  gap: 15px;
}

.assigned-user {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid yellow;
  border-radius: 20px;
}

.user-avatar {
  border-radius: 50%;
}

.modal-description {
  margin-bottom: 20px;
}

.modal-scope {
  margin-bottom: 20px;
}


.modal-breakdown-section {
  margin-bottom: 20px;
}

.breakdown-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
}

.breakdown-tag {
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.breakdown-tag.green {
  /* background-color: #27AE60; */
  border: 1px solid #27AE60;
}

.breakdown-tag.yellow {
  /* background-color: #F39C12; */
  border: 1px solid #F39C12;
}

.breakdown-tag.red {
  /* background-color: #C0392B; */
  border: 1px solid #C0392B;
}

.safety-requirement-buttons button {

  border-radius: 20px;
}

.safety-requirement-divider {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(90deg, #FFFFFF 59.5%, rgba(153, 153, 153, 0) 100%);
}

.safety-requirement-custom-modal .btn-close {
  background-color: #fff;
}


.safety-requirment-team-legend {
  display: flex;
  align-items: center;
  background-color: #0A0A0A;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
}

.safety-requirment-team-legend .team-label {
  margin-right: 20px;
  font-weight: bold;
}

.safety-requirment-team-legend .team {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.safety-requirment-team-legend .circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
}

.safety-requirment-team-legend .yellow {
  background-color: #FFD700;
}

.safety-requirment-team-legend .blue {
  background-color: #03a9f4;
}

.safety-requirment-team-legend .purple {
  background-color: #9c27b0;
}

.safety-requirment-team-legend .team-name {
  font-size: 10px;
  color: #fff;
}


.user-info-modal .modal-content {
  background-color: #0A0A0A;
  border: 2px solid #FFD700; /* Yellow border */
  border-radius: 15px;
  padding: 20px;
  color: #fff;
  text-align: center;
}

.user-info-modal .modal-header {
  border-bottom: none;
}

.user-avatar-modal {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

h3 {
  margin: 0;
  font-size: 18px;
  color: #fff;
}

.user-email {
  color: #3498db;
  text-decoration: none;
  margin-bottom: 15px;
  display: inline-block;
}

.user-description {
  margin-top: 10px;
  font-size: 14px;
  color: #d3d3d3;
}

.user-message-input {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #444;
  background-color: #1E1E1E;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-icons {
  margin-top: 15px;
}

.modal-icons i {
  color: #FFD700;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
}

.user-title {
  margin-top: 20px;
  color: #FFD700;
  font-size: 14px;
}


