.dashboard-container {
    padding: 20px;
    color: #fff;
    /* background-color: #121212; Dark background */
  }
  
  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .column {
    display: flex;
    align-items: center;
  }
  
  .toggle-column {
    flex: 1;
  }
  
  .toggle-label {
    display: flex;
    align-items: center;
    color: #fff;
  }
  
  .toggle-label span {
    margin-right: 10px;
  }
  
  .toggle-switch {
    display: none;
  }
  
  .toggle-slider {
    width: 40px;
    height: 20px;
    background-color: #444;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
  }
  
  .toggle-slider:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #00ff00;
    border-radius: 50%;
    transition: 0.2s;
  }
  
  .toggle-switch:checked + .toggle-slider:before {
    left: 22px;
  }
  
  .indicator-column {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .indicator {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
  }
  
  .checkpoint-dot {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .current-status-diamond,
  .target-goal-diamond {
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 2px solid;
    border-radius: 5px;
    transform: rotate(45deg);
    margin-right: 15px;
  }
  
  .current-status-diamond {
    border-color: #fff; /* Purple */
    background-color: #8a2be2; /* Purple */
  }
  
  .target-goal-diamond.yellow {
    border-color: #ffd700; /* Yellow */
  }
  
  .target-goal-diamond.red {
    border-color: #ff0000; /* Red */
    margin-left: -8px; /* Overlap the yellow diamond slightly */
  }

  
  .timeline-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
  }
  
  .timeline-row {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .timeline-label {
    padding: 10px 20px;
    background-color: #1c1c1c;
    border: 2px solid;
    border-radius: 25px;
    font-weight: bold;
    font-size: 14px;
    width: 20%;
    text-align: center;
    display: inline-block;
  }
  
  .timeline-bar {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    height: 2px;
    background: linear-gradient(to right, #8a2be2, #888); /* Example gradient */
    border-radius: 4px;
    justify-content: space-between; 
  }
  
  .checkpoint {
    width: 9px;
    height: 9px;
    background-color: #888;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  .checkpoint.active {
    background-color: inherit;
  }
  
  .checkpoint.current {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(138, 43, 226, 0.8);
  }
  
  .draggable-handle {
    position: absolute;
    top: -7px;
    width: 16px;
    height: 16px;
    background-color: inherit;
    transform: rotate(45deg); /* Rotate the handle */
    border: 2px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    transform-origin: center;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .handle-text {
    position: relative;
    bottom: 35px; /* Adjust to position the text above */
    text-wrap: nowrap;
    text-align: center;
    transform: rotate(-45deg); /* Counter-rotate the text */
    font-size: 10px;
    color: #fff;
    text-align: center;
  }
  
  
  .draggable-handle:hover {
    background-color: #a64dff;
  }
  
      