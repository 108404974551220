.quality-testing-option-container {
  width: 100%;
  background-color: #000;
  color: #fff;
  /* padding: 20px; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* .quality-testing-option-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.option-test-dropdown {
  width: 150px;
}


.quality-testing-option-menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.content-section {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.image-col {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quality-testing-option-test-image {
  max-width: 100%;
  position: relative;
  bottom: 160px;
  left: 20%;
  height: auto;
  z-index: 1000;
}

.options-col {
  width: 500px; /* Adjusted width */
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  background-color: #111;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Slight shadow for effect */
  position: relative;
  right: 30px;
  /* bottom: 100px; */
  
}



.quality-testing-option-sidebar h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #fff;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
}

.section {
  margin-bottom: 20px;
}

.section h4 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #aaa;
}

.section label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #ddd;
}

.section p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #888;
}

.slider {
  width: 100%;
  margin-top: 10px;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 10px;
  transform: scale(1.2); /* Enlarges checkboxes and radio buttons */
}

.section input[type="range"] {
  width: 100%;
  background: #555;
  outline: none;
  border-radius: 5px;
}


.bottom-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  
}

.range-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.range-slider {
  flex-grow: 1;
  appearance: none;
  width: 100%;
  height: 4px;
  background: #666;
  border-radius: 2px;
  outline: none;
  margin: 0 10px;
}

.range-slider::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background: #FFF;
  border-radius: 50%;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #FFF;
  border-radius: 50%;
  cursor: pointer;
}

.time-label {
  color: #FFF;
  font-size: 12px;
}

.play-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px; /* Space between controls */
  /* margin-top: 10px; */
}

.control-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px; /* Space between button and label */
}

.control-button {
  background-color: #000;
  color: #fff;
  font-size: 18px;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
}

.control-label {
  color: #aaa;
  font-size: 14px;
  text-align: center;
}

.control-button:hover {
  background-color: #222;
}

.control-button span {
  font-size: 18px;
}


.quality-eclipse-circle {
  position: absolute;
  bottom: 170px; /* Adjust this value to control the circle’s distance from the image */
  right: 900px;
  width: 250px;  /* Adjust the size of the circle */
  height: 80px;  /* Keep this small to create an ellipse */
  /* background: rgba(255, 255, 255, 0.2); */
  border-radius: 50%;
  border: 1px solid #FFFFFF
}


.quality-eclipse-style {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  z-index: 2;
}

.image-style {
  width: 100%; /* Make image responsive */
  max-width: 600px; /* Restrict the maximum width */
  z-index: 100;
}

.image-col-style {
  position: relative;
  width: 100%; /* Ensure the container takes full width */
  display: flex;
  justify-content: center;
  align-items: center;
 

}
.btn.show{
background-color: #0a0a0a;
    border-color: #c9d6e2;
} 


.option-test-dropdown .dropdown-toggle {
  border-radius: 10px 0 10px 0; /* Top-left and bottom-right corners curved */
  background-color: #080808; /* Optional: adjust background color */
  color: white; /* Optional: text color */
  padding: 10px 75px;
  font-style: italic;
  border: white solid 2px; /* Remove the default button border */
}



.btn-curve::after {
  display: none; /* Hides the default down arrow */
}

.btn-curve {
  position: relative ! important;
  top: -17px;
}

.btn-curve::after {
  display: none !important; 
}





.custom-dropdown {
  background-color: #111; /* Dark background */
  border: none;
  padding: 10px 0; /* Increased padding for the top and bottom of the dropdown */
  width: 220px; /* Adjusted width if needed */
}

.custom-dropdown .dropdown-item {
  color: #fff;
  background-color: #111;
  display: flex;
  justify-content: space-between;
  padding: 15px 12px; /* Increased padding inside each dropdown item */
  border-bottom: 1px solid #333;
}

.custom-dropdown .dropdown-item:hover {
  background-color: #333; /* Hover effect */
}


.custom-checkbox {
  
  margin-bottom: 10px;
  font-size: 16px;
  width: 10px;
  color: #ddd;
}

.background {
  background-color:#2a2a2a;
}

.btn-switch {
  position: relative;
  top: 15px;
}

.btn-icon {
  background-color:#2a2a2a;
  color: #ddd;
  padding: 10px;
  border-right: none;
}