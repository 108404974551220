.design-stages-container {
    /* padding: 20px; */
    /* background-color: #000; */
    color: #fff;
    /* height: 100vh; */
  }

  .design-content {
    background-color: #000000;
  }
  
  .top-nav {
    /* background-color: #111; */
    /* padding: 10px 0; */
    /* border-radius: 10px; */
    border-bottom: 1px solid #666666;
  }
  
  .breadcrumb {
    font-size: 16px;
    color: #fff;
  }
  
  .arrow {
    margin: 0 5px;
  }
  
  .back-btn {
    color: #fff;
    border: 1px solid #555;
    border-radius: 20px;
    padding: 5px 15px;
    font-size: 14px;
  }
  
  .top-nav-item {
    font-size: 14px;
    color: #aaa;
    margin-right: 20px;
  }
  
  .top-nav-item a {
    color: #fff;
    text-decoration: underline;
  }
  
  .creator-btn {
    color: #fff;
    border-radius: 20px;
    background-color: #444;
    padding: 5px 15px;
    font-size: 14px;
  }
  .main-content {
    flex: 1;
  }
  
  .image-placeholder {
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
  }
  
  .chat-layout {
    background-color: #000000;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    bottom: 85px;
  }
  
  .chat-body {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .message {
    display: flex;
    align-items: flex-start;
  }
  
  .avatar {
    margin-right: 10px;
  }
  
  .message-content {
    /* background-color: #444; */
    padding: 0px 15px;
    border-radius: 10px;
    max-width: 75%;
    color: #fff;
  }
  
  /* .message-text {
    margin: 0;
    font-size: 14px;
  } */
  
  .message-text {
    background-color: #888; /* Light grey background */
    color: #000; /* Black text color */
    padding: 10px 15px; /* Padding inside the text box */
    border-radius: 20px 20px 20px 0px; /* Rounded corners, with a sharper edge on the bottom left */
    max-width: 300px; /* Adjust this value based on your layout */
    margin-bottom: 10px; /* Space below the message */
    font-size: 14px; /* Font size */
    line-height: 1.5; /* Line height for readability */
    position: relative; /* Relative positioning for the tail */
  }
  
  .message-text::after {
    content: '';
    position: absolute;
    bottom: 0; /* Position at the bottom */
    left: -10px; /* Adjust the left position for the tail */
    width: 20px;
    height: 20px;
    background-color: #888; /* Same as background color of the bubble */
    border-bottom-right-radius: 15px; /* Rounded tail */
    transform: rotate(45deg); /* Rotate to form the tail shape */
  }

  .message-text-tail {
    background-color: #888; /* Light grey background */
    color: #000; /* Black text color */
    padding: 10px 15px; /* Padding inside the text box */
    border-radius: 20px 20px 20px 0px; /* Rounded corners, with a sharper edge on the bottom left */
    max-width: 300px; /* Adjust this value based on your layout */
    margin-bottom: 10px; /* Space below the message */
    font-size: 14px; /* Font size */
    line-height: 1.5; /* Line height for readability */
    position: relative; /* Relative positioning for the tail */
  }
  
  .message-text-tail::after {
    content: '';
    position: absolute;
    bottom: 0; /* Position at the bottom */
    right: -10px; /* Adjust the left position for the tail */
    width: 20px;
    height: 20px;
    background-color: #888; /* Same as background color of the bubble */
    border-bottom-right-radius: 15px; /* Rounded tail */
    transform: rotate(45deg); /* Rotate to form the tail shape */
  }
  
  
  .message-image {
    margin-top: 10px;
    border-radius: 10px;
    max-width: 100%;
  }
  
  .timestamp {
    font-size: 12px;
    color: #aaa;
    text-align: center;
    margin: 10px 0;
  }
  
  .response {
    justify-content: flex-end;
  }
  
  .response .message-content {
    /* background-color: #555; */
  }
  
  .response .message-image {
    margin-top: 10px;
  }
  
  /* .highlighted .message-content {
    background-color: #5c2d91;
    border: 1px solid #a64dff;
  } */

  .highlighted .message-content .message-text {
    border: 1px solid #a64dff;
    z-index: 1;
  }

  .highlighted .message-content .message-text::after {
    border-left: 1px solid #a64dff;
    border-bottom: 1px solid #a64dff;
  }
  
  .chat-footer {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .comment-input {
    flex: 1;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 20px;
    border: 1px solid #444;
    background-color: #222;
    color: #fff;
  }
  
  