.routing-production-container {
    color: #fff;
    padding: 20px;
    background-color: #1c1c1c; /* Background color for the entire container */
    border-radius: 10px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .header h5 {
    margin: 0;
  }
  
  .header-actions {
    display: flex;
    gap: 10px;
  }
  
  .edit-button,
  .download-button {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px 10px;
  }
  
  .search-bar {
    width: 200px;
  }
  
  .routing-table {
    border: none;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .routing-table thead th {
    background-color: #333;
    color: #fff;
    border: none;
    text-align: start;
  }
  
  .routing-table tbody tr {
    background-color: #2c2c2c;
    border-top: 1px solid #444;
  }
  
  .routing-table tbody tr.highlighted-row {
    background-color: #444; /* Highlighted row background color */
    color: #fff;
  }
  
  .routing-table tbody tr td {
    text-align: start;
    border: none;
  }

  .routing-production-radio-circle {
    width: 20px; /* Adjust size accordingly */
    height: 20px;
    background-color: #000; /* Adjust background color */
    border: 1px solid #333; /* Border color for the circle */
    border-radius: 50%; /* Makes the div a circle */
    display: inline-block; /* Ensures the circle behaves like an inline element */
  }
  
  