.account-container {
    color: #fff;
    padding: 20px;
    overflow: hidden;
  }
  
  .account-background-image {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('../../../assets/admin/account/Rectangle-1014.png'); /* Replace with actual background image URL */
    background-size: cover;
    background-position: center;
    padding: 40px 20px;
    border-radius: 10px;
    width: 100%;
    height: 220px;
    mix-blend-mode: luminosity;
  }
  
  .profile-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .profile-image {
    width: 250px;
    height: 250px;
    border: 5px solid #a64dff;
    margin-bottom: -200px;
    object-fit: cover;
    position: relative;
    bottom: 200px;
    transform: translateX(50%);
  }

  .profile-name {
    font-family: FONTSPRING DEMO - neue Singular D Light;
    font-size: 46px;
    font-weight: 320;
    line-height: 58.04px;
    letter-spacing: 0.1em;
    text-align: left;
  }
  
  .profile-details h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .email-link {
    color: #a64dff;
    text-decoration: none;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #B7B7B7;
    text-decoration: underline;


  }
  
  .team-manager {
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #8A34FF;

  }
  
  .message-input {
    background-color: #333 !important;
    color: #fff;
    border-radius: 50px !important;
    border: none;
    padding: 10px 20px;
    margin-bottom: 10px;
    /* width: 50% !important; */
  }

  .message-input::placeholder {
    font-family: Arial;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 16.1px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #535353 !important;
  }
  
  .contact-icons {
    display: flex;
    gap: 15px;
    margin-top: 10px;
  }
  
  .contact-icons .contact-icon {
    color: #a64dff;
    font-size: 24px;
  }
  
  .interests-career {
    padding-left: 50px;
    display: flex;
    align-items: center;
  }
  
  .vertical-line {
    height: 250px;
    width: 1px;
    background-color: #fff;
    margin: 0 30px;
  }
  
  .interests h5, .career h5 {
    font-family: FONTSPRING DEMO - neue Singular D Light;
    font-size: 24px;
    font-weight: 320;
    line-height: 30.28px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #FFFFFF;

  }
  
  .career p, .interests p {
    font-size: 14px;
    line-height: 1.6;
    color: #ccc;
    font-family: FONTSPRING DEMO - neue Singular D Light;
    font-size: 16px;
    font-weight: 320;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-align: left;

  }
  