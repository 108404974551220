.second-login-container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: 
      radial-gradient(circle at center, rgba(0, 0, 0, 0.7), transparent 70%),
      url('../../assets/login/images/eclipses-background.png'); 
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  
  .input-field {
    background-color: rgba(51, 51, 51, 0.8) !important;
    color: white !important;
    border: 1px solid transparent !important;
    border-radius: 25px !important;
    padding: 12px 20px !important;
    transition: border 0.3s ease !important;
    background-clip: padding-box !important; /* Ensures that the background color applies only within the padding area */
    box-shadow: 0px 0px 15px rgba(140, 78, 255, 0.5) !important; /* Adds a subtle shadow for better focus */
  }
  
  .input-field:focus {
    outline: none !important;
    border-image: linear-gradient(45deg, #8c4eff, #a86bff) !important;
  }
  
  .input-field::placeholder {
    color: #999 !important;
  }
  
  .second-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px; /* Adjust width to match the button size */
    height: 100px; /* Adjust height to match the button size */
    background-image: url('../../assets/login/images/button-background.png'); /* Replace with your actual background image URL */
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    /* border: 2px solid rgba(140, 78, 255, 0.8); */
    /* box-shadow: 0 0 15px rgba(140, 78, 255, 0.5); */
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .second-login-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(140, 78, 255, 0.1);
    z-index: 1;
    border-radius: 15px;
  }
  
  .second-login-button .button-text {
    z-index: 2;
    font-size: 20px;
    color: white;
    text-align: center;
  }
  
  .second-login-button::after {
    content: "→";
    font-size: 20px;
    color: white;
    position: absolute;
    z-index: 100;
  }
  
  .second-login-button:hover {
    box-shadow: 0 0 20px rgba(168, 107, 255, 0.7);
  }
  
  
  .forgot-password {
    text-align: right;
    color: #888;
  }
  
  .forgot-password a {
    color: #a86bff;
  }
  
  .forgot-password a:hover {
    color: #c07cff;
  }
  