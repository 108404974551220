/* UploadDesign.css */

.upload-design-container {
    padding: 0px;
    background-color: #0A0A0A;
    color: #fff;
    min-height: 100vh;
  }
  
  .upload-design-back-button {
    margin-bottom: 10px;
  }
  
  .file-info-section {
    display: flex;
    align-items: center;
    background-color: #1E1E1E;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 40px;
    color: #fff;
    font-size: 18px;
  }
  
  .file-info-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .upload-type-section {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .upload-type-button {
    width: 100%;
    margin-bottom: 20px;
    padding: 0px;
    border-radius: 50px !important;
    background-color: #333;
    color: #fff;
    font-size: 16px;
  }
  
  .upload-type-button span {
    font-size: 12px;
    color: #bbb;
  }
  
  .folder-selection-section {
    background-color: #1E1E1E;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .folder-table {
    /* Your table styles */
  }
  
  .upload-button-section {
    text-align: center;
    margin-top: 30px;
  }
  
  .upload-button-section .btn-primary {
    background-color: #333;
    border: none;
    padding: 10px 30px;
    border-radius: 20px;
    font-size: 16px;
  }
  
  .upload-design-back-button button {
    width: 100px;
    border-radius: 20px;
  }