.routing-container {
    padding: 20px;
    color: #fff;
  }
  
  .top-navigation {
    margin-bottom: 10px;
  }
  
  .breadcrumb-routing {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 8%;
    font-size: 18px;
  }
  
  .breadcrumb-item-routing {
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 20px;
  }
  
  .breadcrumb-item-routing.active {
    background-color: #fff;
    color: #000;
  }
  
  .breadcrumb-arrow-routing {
    position: relative;
  }
  
  .routing-arrow-icon {
    display: inline-block;
    width: 300px; /* Set the fixed width */
    height: 0px; /* No height since it's a border line */
    border-top: 1px solid rgba(255, 255, 255, 0.7); /* Set the line color and opacity */
    position: relative;
    top: 10px; /* Adjust to position the line correctly */
    right: 50px;
  }
  
  .routing-arrow-icon::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0; /* Positions the arrow on the right end of the line */
    top: -4px; /* Adjust the arrow's vertical position */
    width: 0;
    height: 0;
    border-left: 6px solid rgba(255, 255, 255, 0.7); /* Arrow triangle shape */
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
  

  
  
  .main-content {
    margin-top: 20px;
  }
  
  .left-section,
  .right-section {
    background-color: #2d2d2d;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
  }
  
  .left-section h5,
  .right-section h5 {
    margin-bottom: 20px;
  }
  
  .add-form .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .bottom-content {
    margin-top: 40px;
  }
  
  .bottom-content h5 {
    margin-bottom: 20px;
  }
  
  .add-section {
    margin-top: 20px;
  }
  
  .last-section {
    margin-top: 40px;
  }

  /* add from css code */

  .production-form-routing .add-form {
    background-color: #1c1c1c; /* Background color */
    padding: 20px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #333; /* Border matching the design */
  }
  
  .production-form-routing .form-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    font-size: 14px;
    color: #bbb; /* Lighter text color for the header */
  }
  
  .production-form-routing .form-group {
    margin-bottom: 20px;
  }
  
  .production-form-routing .form-label {
    font-size: 14px;
    color: #bbb; /* Lighter text color */
  }
  
  .production-form-routing .form-input {
    background-color: #333;
    color: #fff;
    border: 1px solid #555;
    border-radius: 10px;
    padding: 10px;
  }
  
  .production-form-routing .form-input::placeholder {
    color: #666; /* Placeholder color */
  }
  
  .production-form-routing .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .production-form .clear-button,
  .save-button {
    width: 45%;
    background-color: #444;
    border-color: #444;
    border-radius: 20px;
    padding: 10px;
  }
  
  .production-form .save-button {
    background-color: #8c8c8c; /* Save button color */
    color: #000;
  }
  
  .production-form .clear-button:hover,
  .save-button:hover {
    background-color: #999;
    border-color: #999;
  }


  
  .finished-products-container {
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #333;
  }

  .finished-products-container-thermal {
   background-color: #1c1c1c;
    padding: 20px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #333;
  }

  .section-title {
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
  }
  
  .product-details p {
    font-size: 14px;
    color: #bbb;
    margin-bottom: 20px;
  }
  
  .finished-products-table {
    border: 1px solid #333;
  }
  
  .table-header {
    font-size: 14px;
    color: #bbb;
    background-color: #2c2c2c;
    border: none;
  }
  
  .table-cell {
    color: #ccc;
    border: none;
  }
  
  .quota-cell {
    text-align: right;
  }
  
  .table-cell:first-child {
    font-weight: bold;
  }
  
  
  /* media query for smaller screens range 1024 to 1440 */
@media (min-width: 1024px) and (max-width: 1275px) { 

  .breadcrumb-routing {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 8%; */
    font-size: 18px;
}
  .routing-arrow-icon {
    width: 50px !important;
  }

}