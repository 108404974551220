/* FileDropZone.css */

.file-drop-zone {
    border: 2px solid #333;
    border-radius: 15px;
    background-color: #1E1E1E;
    color: #bbb;
    padding: 40px;
    text-align: center;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 100vh;
    position: relative;
    transition: background-color 0.3s ease;
  }
  
  .file-drop-zone:hover {
    background-color: #2a2a2a;
  }
  
  .drop-text {
    margin-bottom: 20px;
    font-size: 20px;
    color: #999;
  }
  
  .arrow-down {
    font-size: 48px;
    color: #666;
  }
  