.schedule-container {
    color: #fff;
    padding: 20px;
    /* background-color: #1c1c1c; */
    border-radius: 15px;
  }
  
  h5 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .rbc-calendar {
    /* background-color: #1c1c1c; */
    background: #060607;

    color: #fff;
    border-radius: 10px;
    padding: 10px;
  }
  
  .rbc-month-view {
    border: 1px solid #333;
  }
  
  .rbc-header {
    color: #bbb;
    font-weight: normal;
    font-size: 16px;
    border-bottom: 1px solid #333;
  }
  
  .rbc-date-cell {
    color: #bbb;
  }
  
  .rbc-event {
    background: #4C4C4C;
    font-family: FONTSPRING DEMO - neue Singular D Light;
    font-size: 14px;
    font-weight: 320;
    line-height: 20.62px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #EBEBEB;
    border-radius: 5px;
    /* padding: 5px; */
  }

  .rbc-month-view {
    height:1,213px;
    gap: 0px;
    border-radius: 15px 15px 15px 15px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    box-shadow: 0px 0px 10px 0px #FFFFFFBF inset;

  }
  
  .rbc-today {
    background-color: #2d2d2d;
  }
  
  .rbc-off-range-bg {
    background-color: #333;
  }
  