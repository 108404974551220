.modal-container {
    position: relative;
  }
  
  .vyomhelmet-custom-modal .modal-content {
    background-color: #1c1c1c;
    color: #fff;
    border-radius: 15px;
    border: none;
    width: 530px;
    /* height: 900px; */
    /* overflow-y: auto; */
    box-shadow: none;
  }
  
  .modal-title {
    color: #a64dff;
    font-weight: bold;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .helmet-content {
    padding: 20px;
  }
  
  .helmet-description {
    font-size: 14px;
    margin-bottom: 20px;
    color: #ccc;
  }
  
  .section-title {
    font-size: 16px;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .features-list {
    list-style: none;
    padding: 0;
    color: #ccc;
    margin-bottom: 20px;
  }
  
  .features-list li {
    margin-bottom: 5px;
  }
  
  .progress-section {
    margin-top: 20px;
  }
  
  .progress-circle {
    width: 80px;
    height: 80px;
    border: 4px solid #a64dff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .progress-details {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .progress-details li {
    color: #ccc;
    margin-bottom: 5px;
  }
  
/* Table styling */
.progress-assignments {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.progress-assignments th,
.progress-assignments td {
  color: #fff;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.progress-assignments th {
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}

/* Status dots */
.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.completed {
  background-color: #a64dff;
}

.in-progress {
  background-color: #ccc !important;
}

.unassigned {
  background-color: #fff !important;
}

/* Assigned to column styling */


.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  margin-right: 10px;
  border: 2px solid #fff;
}

.assignee-name {
  color: #fff;
  font-weight: normal;
  font-size: 14px;
}

/* Right-angle arrow icon */
.arrow-icon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.arrow-icon:hover {
  color: #a64dff;
}

/* .assigned-name-profile {

  border: 1px solid yellow;
  border-radius: 50px;
} */

/* For odd occurrences */
.assigned-name-profile:nth-child(odd) {
  border: 1px solid yellow;
  border-radius: 50px;
}

/* For even occurrences */
.assigned-name-profile:nth-child(even) {
  border: 1px solid green; /* Change this to your desired color */
  border-radius: 50px;
}
  
  .view-more {
    color: #a64dff;
    text-decoration: underline;
    cursor: pointer;
  }
  
  
  .vyomhelmet-custom-modal {
    position: absolute;
    top: 10%; /* Adjust this based on where the modal should appear vertically */
    left: 20%; /* Adjust this to align the modal horizontally */
    transform: translate(-50%, 0);
    height: 50px;
  }


  
  .vyomhelmet-custom-modal::before {
    content: '';
    position: absolute;
    top: 4%; /* Adjust to the correct position to link with the circle */
    left: -37%;
    width: 100%;
    height: 2px;
    background-color: #a64dff;
    transform: translateY(-50%);
  }
  
