.thermal-insulation-layering-container {
    background-color: #1c1c1c;
    /* padding: 20px; */
    /* border-radius: 15px; */
    color: #fff;
    /* width: 100%; */
    /* border: 1px solid #333; */
  }
  
  .section-title {
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
  }
  
  .steps-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between each step */
  }
  
  .step {
    display: flex;
    align-items: center;
  }
  
  .step-title {
    width: 60%;
    font-size: 16px;
    font-weight: bold;
    color: #bbb;
    position: relative;
  }
  
  .step-content {
    width: 100%;
    font-size: 14px;
    color: #ddd;
    padding-left: 20px;
    border-left: 2px solid #444;
    position: relative;
  }
  
  .step:before {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    width: 20px;
    height: 2px;
    background-color: #444;
    transform: translateY(-50%);
  }
  
  .step:after {
    content: '';
    position: absolute;
    left: calc(100% + 20px);
    top: 50%;
    width: 10px;
    height: 10px;
    border: solid #444;
    border-width: 0 2px 2px 0;
    transform: translateY(-50%) rotate(45deg);
  }
  