.admin-layout {
  display: flex;
  flex-direction: column;
  background: 
      linear-gradient(
          to bottom, 
          rgba(0, 0, 0, 0.7) 0%, 
          rgba(6, 6, 6, 0.7) 100%
      ),
      url('../../assets/admin/account/image-19.png');
  background-size: cover;
  background-position: center;
}

.top-navbar {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: black;
  z-index: 1000;
}

.layout-wrapper {
  display: flex;
  flex: 1;
  padding-top: 60px; /* Adjust according to the height of TopNavbar */
}

/* Sidebar Styles */
.sidebar {
  width: 270px;
  height: calc(100vh - 60px); /* Adjust height to fit below the navbar */
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 999;
  padding-top: 30px;
  border-right: 3px solid #6d6d6d;
  background-color: black;
  border-top-right-radius: 20px;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 80px; /* Reduced width when collapsed */
}

/* Main Content Styles */
.main-content {
  transition: padding-left 0.3s ease;
}

.main-content.expanded {
  padding-left: 270px; /* Sidebar expanded width */
}

.main-content.collapsed {
  padding-left: 80px; /* Sidebar collapsed width */
}
