/* Sidebar styles */
.sidebar {
    width: 250px;
    height: calc(100vh - 60px); /* Adjust height to fit below the navbar */
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 999;
    padding-top: 30px;
    border-right: 3px solid #6d6d6d;
    background-color: black;
    border-top-right-radius: 20px;
    overflow-y: auto;
    transition: width 0.3s ease;
}

/* Scrollbar styles for the sidebar */
.sidebar::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
}

.sidebar::-webkit-scrollbar-track {
    background: #2c2c2c; /* Background color for the scrollbar track */
    border-radius: 10px; /* Rounded corners for the scrollbar track */
}

.sidebar::-webkit-scrollbar-thumb {
    background: #6d6d6d; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
    border: 2px solid #000; /* Optional border to create spacing and contrast */
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background: #888; /* Lighter shade when hovered for better visibility */
}

.sidebar.collapsed {
    width: 80px; /* Reduced width when collapsed */
}

.accordion .accordion-button {
    background: #000 !important;
    color: #fff !important;
}

.accordion .accordion-button.collapsed {
    background: #000 !important;
    color: #fff !important;
}

.accordion .accordion-collapse.collapse.show {
    background: #000 !important;
    color: #fff !important;
    border: none !important;
}


.sidebar ul {
    list-style-type: none;
    padding-left: 10px;
}

.sidebar li {
    display: flex;
    align-items: center;
    padding: 15px;
}

.sidebar ul li {
    margin: 0;
    padding: 10px 20px;
    border-top: 1px solid #333;  /* Top border */
    border-bottom: 1px solid #333; /* Bottom border */
}

.sidebar ul li:first-child {
    border-top: none; /* Remove top border for the first item */
}

.sidebar ul li:last-child {
    border-bottom: none; /* Remove bottom border for the last item */
}


.sidebar li a {
    color: #fff;
    text-decoration: none;
}

.sidebar li a .fas {
    margin-right: 10px;
}

.sidebar li a img {
    margin-right: 10px;
}

.sidebar.collapsed li a {
    justify-content: center;
}

.sidebar.collapsed li a .fas {
    margin-right: 0;
}

.sidebar.collapsed li a img {
    margin-right: 0;
}

.main-content.expanded {
    padding-left: 270px; /* When sidebar is expanded */
    transition: padding-left 0.3s ease;
}

.main-content.collapsed {
    padding-left: 80px; /* When sidebar is collapsed */
    transition: padding-left 0.3s ease;
}

/* Top Navbar styles */
.toggle-sidebar-btn {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}
