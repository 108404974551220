
.popover-body {
    padding: 20px !important;
    background-color:  #1E1E1E;
    color: white;
    border-radius: 5px;
    border: 1px solid #FFCC00;
}


.tooltip-body {
    /* padding: 10px; */
    /* background-color: #1E1E1E; */
    color: white;
    /* border-radius: 5px; */
    /* border: 1px solid #FFCC00; */
  }
  
  .tooltip-header {
    display: flex;
    align-items: center;
  }
  
  .tooltip-avatar {
    display: flex;
    align-items: center;
    border: 1px solid blue;
    border-radius: 15px;
    margin-left: 5px;
  }
  
  .tooltip-avatar img {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  
  .tooltip-row {
    margin-top: 5px;
  }
  
  .red-text {
    color: red;
  }
  