.engineering-view-modal .modal-content {
    background-color: #1E1E1E;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    border: 2px solid #9c27b0; /* Example of border color */
  }

  .engineering-preview-modal .modal-content {
    background-color: #1E1E1E;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    border: 2px solid #9c27b0; /* Example of border color */
  }